import React from "react"
import Layout from "../components/layout.en"
import "./business.css"
import HomePageYAMLData from "../../content/home.en.yaml"

export default () => (
  <Layout pageId={"/en/business"}>
    <div className='w-100 banner'>
      <div className='container  h-100'>
        <div className='row align-items-center h-100 '>
          <div className='col-12'>
            <div className='col-auto px-0' style={{ fontSize: 70, fontWeight: "bold", color: "#fff" }}>
              {HomePageYAMLData.business_title}
              {/*核心业务*/}
            </div>
            <div className='w-100'/>
            <div>
              <div style={{ backgroundColor: "#fff", height: 2, width: 196, marginTop: 10}}/>
            </div>
            <div className='w-100'/>
            <div className='col-8 px-0' style={{ fontSize: 18, lineHeight: "30px", backgroundColor: 'rgba(0, 87, 184, 0.21)',  marginTop: 20 }}>
              <div style={{padding: 37, color: "#fff",textAlign:'justify'}}>
                {HomePageYAMLData.business_title_content}
                {/*我们核心实验室利用先进的软件平台，结合经验丰富的临床专家团队、介入放射专家团队和放射影像团队，能提供缺血性脑血管病的脑组织及血管相关的影像判读，进行精准定性和定量分析，并给出规范化的诊断报告，满足临床和科研项目影像判读需求。*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className='row align-items-center px-0 justify-content-center' style={{ marginTop: 50 }} >
        <div className='col-lg-6 col-12 px-0 order-lg-1 order-1'  name="DSA">
          <img src='/images/business/DSA.png' className='w-100 msg-img'/>
        </div>
        <div className='col-lg-6 col-12 px-0 order-lg-2 order-2'>
          <div className='msg-div'>
            <div className='msg-title'>
              {HomePageYAMLData.business_category_title_1}
              {/*脑血管造影定性和定量分析（DSA）*/}
            </div>
            <div style={{ marginTop: 25 }}>
              <ul className='msg-list'>
                {HomePageYAMLData.business_category_title_1_li.map(item => {
                  return(<li>{item}</li>)
                })}

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='row align-items-center justify-content-center' name = "MRI">
        <div className='col-lg-6 col-12 px-0 order-lg-1 order-2'>
          <div className='msg-div'>
            <div className='msg-title'>
              {HomePageYAMLData.business_category_title_2}
              {/*脑核磁共振定性和定量分析（MRI）*/}
            </div>
            <div style={{ marginTop: 25 }}>
              <ul className='msg-list'>
                {HomePageYAMLData.business_category_title_2_li.map(item => {
                  return(<li>{item}</li>)
                })}

              </ul>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-12 px-0 order-lg-2 order-1'>
          <img src='/images/business/MRI.png' className='w-100 msg-img'/>
        </div>
      </div>

      <div className='row align-items-center justify-content-center' name = "HRMRI">
        <div className='col-lg-6 col-12 px-0 order-lg-1 order-1'>
          <img src='/images/business/HRRMRI.png' className='w-100 msg-img'/>
        </div>
        <div className='col-lg-6 col-12 px-0 order-lg-2 order-2'>
          <div className="msg-div">
            <div className='msg-title'>
              {HomePageYAMLData.business_category_title_3}
              {/*脑血管高分辨核磁共振分析（HRMRI）*/}
            </div>
            <div style={{ marginTop: 25 }}>
              <ul className='msg-list'>
                {HomePageYAMLData.business_category_title_3_li.map(item => {
                  return(<li>{item}</li>)
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='row align-items-center justify-content-center' name = "OCT">
        <div className='col-lg-6 col-12 px-0 order-lg-1 order-2'>
          <div className='msg-div'>
            <div className='msg-title'>
              {HomePageYAMLData.business_category_title_4}
              {/*血管内光学相干断层成像（OCT）*/}
            </div>
            <div style={{ marginTop: 25 }}>
              <ul className='msg-list'>
                {HomePageYAMLData.business_category_title_4_li.map(item => {
                  return(<li>{item}</li>)
                })}

              </ul>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-12 px-0 order-lg-2 order-1'>
          <img src='/images/business/OCT.png' className='w-100 msg-img'/>
        </div>
      </div>

      <div className='row align-items-center justify-content-center' name="CFD">
        <div className='col-lg-6 col-12 px-0 order-lg-1 order-1'>
          <img src='/images/business/CFD.png' className='w-100 msg-img'/>
        </div>
        <div className='col-lg-6 col-12 px-0 order-lg-2 order-2'>
          <div className='msg-div'>
            <div className='msg-title'>
              {HomePageYAMLData.business_category_title_5}
              {/*计算机流体力学分析（CFD）*/}
            </div>
            <div style={{ marginTop: 25 }}>
              <ul className='msg-list'>
                {HomePageYAMLData.business_category_title_5_li.map(item => {
                  return(<li>{item}</li>)
                })}

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='row align-items-center justify-content-center' style={{ marginBottom: 30 }} name = "VU">
        <div className='col-lg-6 col-12 px-0 order-lg-1 order-2'>
          <div className='msg-div'>
            <div className='msg-title'>
              {HomePageYAMLData.business_category_title_6}
              {/*血管超声分析（VU）*/}
            </div>
            <div style={{ marginTop: 25 }}>
              <ul className='msg-list'>
                {HomePageYAMLData.business_category_title_6_li.map(item => {
                  return(<li>{item}</li>)
                })}

              </ul>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-12 px-0 order-lg-2 order-1'>
          <img src='/images/business/TCD.png' className='w-100 msg-img'/>
        </div>
      </div>
    </div>
  </Layout>
)
